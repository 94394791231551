<template>
  <v-dialog v-model="dialog" class="dialog" max-width="440" persistent @click:outside="handleCloseModal" @keydown="CloseWithEsc">
    <v-card class="card" style="overflow: hidden">
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        color="primary accent-4"
        top
      ></v-progress-linear>
      <v-row class="px-0 mx-0 align-center">
        <img :src="pivotAddLogo" alt="Pivotad logo" loading="lazy" width="165" height="50"/>
        <v-spacer></v-spacer>
        <v-btn icon @click="handleCloseModal">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-row>
      <h3 class="mt-5" style="font-family: 'Raleway light', sans-serif">
        {{ $t("login_card.title") }}
      </h3>
      <v-form
        ref="form"
        v-model="valid"
        class="text-right form"
        lazy-validation
      >
        <v-text-field
          v-model="email"
          :rules="emailRules"
          class="input-border mt-4 py-0 my-0 font-family-raleway-medium"
          height="60"
          outlined
          :placeholder="$t('login_card.email_input_text')"
        />
        <v-text-field
          v-model="password"
          :append-icon="pass ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="emptyRules"
          :type="pass ? 'text' : 'password'"
          class="input-border font-family-raleway-medium"
          height="60"
          outlined
          :placeholder="$t('login_card.password')"
          @click:append="pass = !pass"
        ></v-text-field>
        <v-row class="pa-0 ma-0 mt-4 align-center d-flex">
          <div class="d-flex align-center">
            <v-simple-checkbox
              v-model="checkbox"
              :rules="emptyRules"
              color="indigo"
              class="align-self-baseline"
              required
            ></v-simple-checkbox>
            <div class="pb-0 mb-0 font-raleway-regular" style="white-space: normal;text-align: start">
              {{ $t("login_card.agree_terms_text") }}
              <a class="font-family-raleway-medium" href="/terms-of-use" target="_blank">{{
                $t("login_card.agree_terms_text_link")
              }} | </a>
              <a class="font-family-raleway-medium" href="/privacy-policy" target="_blank">{{
                $t("login_card.agree_policy_text_link")
              }}</a>
            </div>
          </div>
        </v-row>
        <v-btn
          :loading="loading"
          class="button login-btn mt-7"
          height="60"
          @click="handleClickLogin"
        >
          <h4 style="font-family: 'Raleway light', sans-serif">
            {{ $t("login_card.login_button") }}
          </h4>
        </v-btn>
        <p class="text-center mt-4 mb-0 pb-0 font-raleway-regular">
          {{ $t("login_card.haveno_account_text") }}
          <span
            @click="handleChangeToRegister"
            class="font-family-raleway-medium"
            >{{ $t("register") }}</span
          >
        </p>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { accountService } from "../../../services/account";
import pivotAddLogo from "../../../assets/icon/Pivotadd_black.svg";
import { mapActions } from "vuex";

export default {
  name: "Login",
  props: {
    dialog: Boolean,
  },
  data: () => ({
    pivotAddLogo,
    loading: false,
    valid: true,
    checkbox: false,
    pass: false,
    email: "",
    password: "",
    emptyRules: [(v) => !!v || this.$t("required_text")],
    emailRules: [
      (v) => !!v || this.$t("required_text"),
      (v) => /.+@.+\..+/.test(v) || this.$t("email_must_valid"),
    ],
  }),
  methods: {
    ...mapActions(["setUser"]),
    handleChangeToRegister() {
      this.email = '';
      this.password = '';
      this.checkbox = false;
      this.$emit("closeModal");
      this.$emit("openSignUp");
    },
    handleCloseModal(){
      this.email = '';
      this.password = '';
      this.checkbox = false;
      this.$emit("closeModal");
    },
    CloseWithEsc(event){
      if(event.key==='Escape'){
        this.email = '';
        this.password = '';
        this.checkbox = false;
        this.$emit('closeModal');
      }
    },
    async handleClickLogin() {
      await this.$refs.form.validate();
      const data = {
        email: this.email,
        password: this.password,
      };
      if (this.valid) {
        this.loading = true;
        try {
          const res = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/users/authenticate`,
            data
          );
          const { token } = res.data;
          localStorage.setItem("token", res?.data?.token);
          localStorage.setItem("userId", res?.data?.user.id);
          this.setUser(res.data.user);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

          const userId = res?.data?.user.id;
          if (userId) {
            const account = await accountService.getByUserId(userId);
            if (account?.data.length > 0) {
              const accId = account?.data[0]?.customerId;
              localStorage.setItem("customerId", accId);
              window.location.href = "/my-campaigns";
            } else {
              window.location.href = "/welcome";
            }
          }
        } catch (err) {
          const errCode = err?.response?.statusText;
          this.$notify({
            group: "foo",
            type: "error",
            title: errCode ? errCode : this.$t("something_wrong"),
          });
        }
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  border-radius: 20px !important;
}

.card {
  width: 100% !important;
  border-radius: 20px !important;
  padding: 40px;
  @media screen and (max-width: 600px) {
    padding: 40px 20px 20px 20px!important;
  }
  @media screen and (max-width: 450px) {
    padding: 30px 15px 15px 15px!important;
  }
}

.form {
  span {
    color: #0057ff !important;
    cursor: pointer;
  }

  .login-btn {
    width: 100%;
    font-weight: bold;
  }
}
</style>
